@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;600;700&display=swap');
@import url('./vars/app_vars.css');
@import url('./app/googleButton.css');

#root {
  height: 100% !important;
  width: 100% !important;
  overflow: hidden !important;
}

.ant-ribbon-wrapper {
  height: 100%;
}

.ant-input:hover {
  border-right-width: var(--ql-border-width-default) !important;
}
.ant-input:focus {
  border-right-width: var(--ql-border-width-default) !important;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-right-width: var(--ql-border-width-default) !important;
}

.ant-radio-button-wrapper:first-child {
  border-left: 1px solid var(--ql-border-color-default);
}

.ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
  border-right-width: var(--ql-border-width-default) !important;
}

.ant-input-group-addon {
  border-width: var(--ql-border-width-default) !important;
  border-color: var(--ql-border-color-default) !important;
  border-left: unset !important;
}

.ant-form input[type='range'] {
  border: unset !important;
}

.ant-input[disabled]:hover {
  border-width: var(--ql-border-width-default) !important;
  border-color: var(--ql-border-color-default) !important;
}

.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
  padding: 2px var(--input-padding-horizontal-sm) !important;
  border-right-width: var(--ql-border-width-default) !important;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-right-width: var(--ql-border-width-default) !important;
}

.ant-input-group > .ant-input:last-child, .ant-input-group-addon:last-child {
  border-color: var(--ql-border-color-default) !important;
  border-width: var(--ql-border-width-default) !important;
}

.input-addon {
  border-color: var(--ql-border-color-default) !important;
  border-width: var(--ql-border-width-default) !important;
  border-left-width: 0px !important;
  border-top-right-radius: var(--input-border-radius-base);
  border-bottom-right-radius: var(--input-border-radius-base);
}


@media print {
  @page { 
    size: auto;
    margin-top: 32px;
    margin-bottom: 32px;
   }
}

.ant-input-number-focused {
  border-color: var(--ql-color-accent1) !important;
  border-right-width: 2px !important;
}

.ant-input-number:hover {
  border-right-width: 2px !important;
}

.ant-input-number {
  border-right-width: var(--ql-border-width-default) !important;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: var(--input-disabled-bg);
}
.ant-input-number-disabled:hover {
  border-width: 2px !important;
  border-color: var(--input-hover-border-color) !important;
}