:root {
  /* Colors */
  --ql-color-accent1: rgb(51, 95, 230);
  --ql-color-accent1-t-lighten1: rgba(51, 95, 230, 0.72);
  --ql-color-accent1-t-lighten2: rgba(51, 95, 230, 0.56);
  --ql-color-accent1-t-lighten5: rgba(51, 95, 230, 0.24);
  --ql-color-accent1-t-lighten7: rgba(51, 95, 230, 0.06);

  --ql-color-accent2: rgb(11, 19, 40);
  --ql-color-accent2-t-lighten1: rgba(11, 19, 40, 0.64);
  --ql-color-accent2-t-lighten2: rgba(11, 19, 40, 0.4);
  --ql-color-accent2-t-lighten3: rgba(11, 19, 40, 0.32);
  --ql-color-accent2-t-lighten4: rgba(11, 19, 40, 0.2);
  --ql-color-accent2-t-lighten5: rgba(11, 19, 40, 0.1);
  --ql-color-accent2-t-lighten6: rgba(11, 19, 40, 0.05);

  --ql-color-neutral1: rgba(11, 19, 40, 1);
  --ql-color-neutral1-t-lighten1: rgba(11, 19, 40, 0.73);
  --ql-color-neutral1-t-lighten2: rgba(11, 19, 40, 0.61);
  --ql-color-neutral1-t-lighten3: rgba(11, 19, 40, 0.49);
  --ql-color-neutral1-t-lighten4: rgba(11, 19, 40, 0.32);
  --ql-color-neutral1-t-lighten5: rgba(11, 19, 40, 0.18);
  --ql-color-neutral1-t-lighten6: rgba(11, 19, 40, 0.1);
  --ql-color-neutral1-t-lighten7: rgba(11, 19, 40, 0.05);

  --ql-color-danger: #DA183C;
  --ql-color-danger-t-lighten1: rgba(218, 24, 60, 0.72);

  --ql-color-white: rgb(255, 255, 255);

  --ql-font-size-h1: 32px;
  --ql-font-size-h2: 24px;
  --ql-font-size-h3: 20px;
  --ql-font-size-h4: 16px;
  --ql-font-size-h5: 14px;

  --ql-line-height-h1: 40px;
  --ql-line-height-h2: 32px;
  --ql-line-height-h3: 28px;
  --ql-line-height-h4: 24px;
  --ql-line-height-h5: 20px;

  --ql-component-height-md: 48px;
  --ql-border-width-default: 2px;
  --ql-form-item-label-font-size-base: var(--ql-typography-font-size-md);
  --ql-border-color-default: var(--ql-color-neutral1-t-lighten5);

  /* Inputs */
  --input-bg: var(--ql-color-white);
  --input-placeholder-color: #0B13289C;
  --input-color: var(--ql-typography-text-color-primary);
  --input-padding-horizontal-base: 20px;
  --input-hover-border-color: var(--ql-color-neutral1-t-lighten5);
  --input-addon-bg: var(--ql-color-white);
  /* --ql-select-bg */
  --ql-component-height-sm: 28px;

  /* Card */
  --card-background: var(--ql-color-white);

  /* Collapse */
  --collapse-header-bg: var(--ql-color-accent1-t-lighten6);

  /* Typography */
  --ql-typography-text-color-primary: #0B1328;
  --ql-typography-text-color-secondary: #0B1328BA;
  --ql-typography-text-color-success: #12892C;
  --ql-typography-text-color-danger: #DA183C;
  --ql-state-disabled-color: #0B13287D;
  --ql-typography-heading-color: var(--ql-typography-text-color-primary);
  --ql-typography-heading-font-weight: 600;

  /* Link */
  --breadcrumb-link-color-hover: var(--ql-color-accent1-t-lighten5);

  /* Popover */
  --popover-bg: var(--ql-color-white);

  /* Dropdown */
  --dropdown-menu-bg: var(--ql-color-white);

  /* Select */
  --ql-select-dropdown-bg: var(--ql-color-white);
  --input-height-sm: 28px;

  /* Slider */
  --slider-rail-background-color: var(--ql-color-accent1-t-lighten1);
  --slider-handle-background-color: var(--ql-color-accent1);
  --slider-handle-color: var(--ql-color-accent1);

  /* Message */
  --message-notice-content-bg: var(--ql-color-white);
  --notification-bg: var(--ql-color-white);

  /* Divider */
  --divider-color: var(--ql-color-neutral1-t-lighten5);

  /* Tag */
  --tag-default-bg: var(--ql-color-dark-t-lighten5);
  --tag-default-color: var(--ql-color-dark);

  /* Menu */
  --ql-menu-item-color: var(--ql-typography-text-color-secondary);

  /* Wrappers */
  --ql-layout-background: unset;
  --ql-footer-background: unset;

  /* Custom */
  --checkbox-checked-bg: var(--ql-color-accent1);
  --aside-bg: var(--ql-color-neutral1-t-lighten7);

  --ql-font-family-main: 'Work Sans', sans-serif;
  --ql-font-family-headings: 'Work Sans', sans-serif;

  /* Outline */
  --ql-outline-color-danger:  var(--ql-color-danger-t-lighten4);

  /* Layout */
  --ql-general-layout-px-xxs: 16px; /* mobile (portrait): 0 - 576px */

  --ql-layout-background: var(--ql-color-dark-t-lighten6);

  /* Aside */
  --ql-aside-background: var(--ql-color-white);
  --ql-aside-padding: 24px;

  /* Button */
  --btn-border-color: var(--ql-color-dark-t-lighten4);
  --btn-default-hover-bg: transparent;
  --btn-default-hover-color: var(--ql-color-accent1);
  --btn-default-bg: transparent;
  --btn-default-border: var(--ql-color-neutral1-t-lighten5);
  --btn-padding-horizontal-base: 24px;
  --btn-font-weight: 500;
  --btn-default-color: var(--ql-typography-text-color-primary);

  --btn-text-hover-bg: var(--ql-color-dark-t-lighten5);
  --btn-text-hover-color: var(--ql-color-dark);

  /* Dropdown */
  --dropdown-item-default-bg-hover: var(--ql-color-dark-t-lighten5);
  --dropdown-item-danger-bg-hover: var(--ql-color-danger-t-lighten5);

  /* Menu */
  --ql-menu-group-title-font-weight: var(--ql-font-weight-semibold);
  --ql-menu-group-title-color: var(--ql-color-dark-t-lighten2);
  --ql-menu-group-title-padding: 8px 24px;
  --ql-menu-item-active-bg: var(--ql-color-accent1-t-lighten7);

  /* Account */
  --ql-account-avatar-box-shadow-hover: inset 0 0 0 2px var(--ql-color-accent1);
  --ql-form-item-vertical-spacing-without-message: 24px;

  /* Card */
  --ql-card-background: var(--ql-color-accent1-t-lighten6);
  --ql-card-border-default: 1px solid transparent;
  --ql-card-border-selected: 1px solid var(--ql-color-accent1-t-lighten4);

  /* Grid */
  --ql-grid-gutter: var(--ql-grid-gutter-sm);
  --ql-grid-gutter-negative: calc(var(--ql-grid-gutter-sm) * -1);

  /* Header */
  --header-background-color: #fff;
  --header-padding-top: 12px;
  --header-padding-bottom: 12px;
  --header-box-shadow: none;
  --header-border-bottom-color: #efefef;
  --header-border-bottom-style: solid;
  --header-border-bottom-width: 1px;

  /* Label */
  --ql-form-item-label-color: var(--ql-typography-text-color-primary);
}

/* Grid (breakpoints from @qonsoll/react-design) */
@media (min-width: 576px) {
  :root {
    --ql-grid-gutter: var(--ql-grid-gutter-sm);
    --ql-grid-gutter-negative: calc(var(--ql-grid-gutter-sm) * -1);
  }
}
@media (min-width: 768px) {
  :root {
    --ql-grid-gutter: var(--ql-grid-gutter-md);
    --ql-grid-gutter-negative: calc(var(--ql-grid-gutter-md) * -1);
  }
}
@media (min-width: 992px) {
  :root {
    --ql-grid-gutter: var(--ql-grid-gutter-lg);
    --ql-grid-gutter-negative: calc(var(--ql-grid-gutter-lg) * -1);
  }
}
@media (min-width: 1200px) {
  :root {
    --ql-grid-gutter: var(--ql-grid-gutter-xl);
    --ql-grid-gutter-negative: calc(var(--ql-grid-gutter-xl) * -1);
  }
}
@media (min-width: 1600px) {
  :root {
    --ql-grid-gutter: var(--ql-grid-gutter-xxl);
    --ql-grid-gutter-negative: calc(var(--ql-grid-gutter-xxl) * -1);
  }
}